import React from 'react';
import footerLogo from "../../img/logo.png"
import {AiOutlineTwitter} from "react-icons/ai"
import {BsDiscord} from "react-icons/bs"
import footerLince from "../../img/image 8 (3).png"
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <div id='footer'>
            <div className='container'>
                <div className='footer--line'></div>

                <div className='footer'>
                    <div className='footer--info'>
                        <img className='main-footLogo'src={footerLogo} alt=''/>
                        <div className='footer--info__links'>
                        <NavLink to={"/"}><p>Home</p></NavLink>
                        <NavLink to={"/mint"}><p>Mint</p></NavLink>
                        <NavLink to={"https://bridge.fronk.world/"} target="_blank"><p>Bridge NFT</p></NavLink>
                        <NavLink to={"https://games.fronk.world/#faq"}><p>Faq</p></NavLink>
                      
                        </div>
                        <div>
                            <div className='footer--info__dop'>
                            <NavLink to={"https://fronk-world.gitbook.io/intro/privacy/privacy-policy"} target="_blank" rel="noopener noreferrer">  <p>Privacy policy</p></NavLink>
                            <NavLink to={"https://fronk-world.gitbook.io/intro/introduction/story"} target="_blank" rel="noopener noreferrer">  <p>whitepaper</p></NavLink>
                            </div>
                            <div className='footer--info__logos'>
                                <a href="   https://link3.to/fronk_world"> <img src={footerLince} alt=''/></a>
                                <a href="https://twitter.com/FronkWorld"><p><AiOutlineTwitter className='footer-logo'/>
                                </p></a>
                                <a href="https://discord.com/invite/saH5FYreF7 "><p><BsDiscord className='footer-logo'/>
                                </p></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;