import './App.scss';
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import {Route, Routes} from "react-router-dom";
import FirstPage from "./components/firstPage/firstPage";
import LandSale from "./components/secondPage/secondPage";
import Mint from "./components/mintPage/mintPage";
function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path={"/"} element={<FirstPage/>}/>
        <Route path={"/second"} element={<LandSale/>}/>
        <Route path={"/mint"} element={<Mint/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
