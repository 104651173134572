import React from 'react';
import line from "../../img/line.png"
import pattern from "../../img/pattern_lines.png"
import box from "../../img/Box (1).png"
import avatar from "../../img/photo.png"
import mike from "../../img/Group 12 (1).png"
import shirt from "../../img/Union (1).png"
import cross from "../../img/Group 16 (1).png"
import box2 from "../../img/Box (2).png"
import quest from "../../img/quest.png"
import star from "../../img/star.png"
import people from "../../img/people.png"
import karona from "../../img/karona.png"
import {BsArrowRight} from "react-icons/bs";
import { useState } from 'react';

const FirstPage = () => {
    const information = [
        {
          id: 1,
          zagalovok: "What is fronk world?",
          text: "Fronk World is a flexible social gamified platform for engaging and effective interaction between crypto projects and users. It's a portal where every crypto enthusiast can find something to do in the world of WEB3.",
        },
        {
          id: 2,
          zagalovok: "WHY LAYER ZERO?",
          text: "The updated concept of Fronk World does not involve having a central blockchain. Our goal is to make our platform accessible to everyone, regardless of which blockchain the user prefers. The Layer Zero technology allows us to achieve this.",
        },
        {
          id: 3,
          zagalovok: "IS FRONK WORLD A GAME?",
          text: "Fronk World may include gaming elements and features, but it's not limited to games. It's more than just games. It's a flexible social platform for interaction between crypto projects and users that can encompass various aspects of WEB3, including gaming elements, social interaction, and many other features. Nevertheless, the platform includes several full-fledged gaming modes that can function as standalone mini-games.",
        },
        {
          id: 4,
          zagalovok: "How to start using the platform?",
          text: "At the moment, to use the platform, you will need a Fronk Ticket NFT.",
        },
        {
          id: 5,
          zagalovok: "WILL THE PLATFORM HAVE A TOKEN?",
          text: "Yes, we are planning to launch a token",
        },
        {
          id: 6,
          zagalovok: "What is Fronk Ticket NFT?",
          text: "Fronk Ticket NFT is our genesis collection that gives holders access to the platform and count on other bonuses in the future.",
        },
       ];
    
      const [activeIndex, setActiveIndex] = useState(null);
      const handleClick = (index) => {
        if (index === activeIndex) {
          setActiveIndex(null);
        } else {
          setActiveIndex(index);
        }
      };
    return (
        <div id="first">
            <div id="hero">
                <div className="container">
                    <div className="hero">
                        <div className="hero--top">
                            <h1>The first</h1>
                            <div className="hero--top__line">
                                <img src={line} alt="img"/>
                                <h2>social</h2>
                            </div>
                        </div>
                        <div className="hero--circle"></div>
                        <div className="hero--center">
                            <h1>gaming</h1>
                            <img src={box} alt="img"/>

                            <div className="hero--center__line">
                                <h2>platform</h2>
                            </div>
                        </div>
                        <h3>Play, complete web3 quests, and receive rewards</h3>
                    </div>
                </div>
            </div>
            <div id="fronk">
                <div className="marquee">
                    <div>
                        <h1>What is Fronk World</h1>
                        <BsArrowRight className="icon"/>
                        <h1 className="transparent">What is Fronk World</h1>
                        <BsArrowRight className="icon"/>
                        <h1>What is Fronk World</h1>
                        <BsArrowRight className="icon"/>
                        <h1 className="transparent">What is Fronk World</h1>
                    </div>
                </div>
                <div className="circle"></div>
                <div className="container">
                    <div className="title">
                        <div className="title--left">
                            <h4>Fronk World is a social gamified platform. We are developing a unified space for crypto 
                                projects, allowing interactive engagement with the audience in a gamified format. Fronk World 
                                enables both on-chain and off-chain quests, educational events, branded gaming tournaments, 
                                in-game AMA sessions, and much more. Fronk World is a multifunctional platform for building 
                                communities and engaging with the audience.
                                We are building a fully on-chain protocol using Layer Zero technology. 
                                Our goal is to create a flexible platform that can be utilized by any user on any EVM network.
                               </h4>
                            <img src={pattern} alt="img"/>
                        </div>
                        <iframe className="title--video"
                                width="723"
                                height="400"
                                src="https://www.youtube.com/embed/Xn8mE2xiu-I"
                                title="Fronk World - new play 2 earn game and social platform"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div id="avatar">
                <div className="marquee">
                    <div>
                        <h1>Customize your Avatar</h1>
                        <BsArrowRight className="icon"/>
                        <h1 className="transparent">Customize your Avatar</h1>
                        <BsArrowRight className="icon"/>
                        <h1>Customize your Avatar</h1>
                        <BsArrowRight className="icon"/>
                    </div>
                </div>
                <div className="marquee">
                    <div className='cust-ava'>
                        <h1>Customize your Avatar</h1>
                        <BsArrowRight className="icon"/>
                        <h1 className="transparent">Customize your Avatar</h1>
                        <BsArrowRight className="icon"/>
                        <h1>Customize your Avatar</h1>
                        <BsArrowRight className="icon"/>
                    </div>
                </div>
                <div className="circle"></div>
                <div className="container">
                    <div className="avatar">
                            <img className='ava-img'src={avatar} alt="img"/>

                        <div className="avatar--right">
                            <h4>Fronk World is a unified space for any WEB3 activities. Your avatar is your WEB3 version that can 
                                be customized and improved. The avatar reflects your experience, preferences, status, and interests. 
                                It serves as both your unified WEB3 profile, a gaming character, and a business card in the Fronk World.</h4>
                            <div className="avatar--right__bottom">
                                <div className="avatar--right__bottom--clothes">
                                    <img src={mike} alt="img"/>
                                    <img src={shirt} alt="img"/>
                                    <img src={cross} alt="img"/>
                                    <img src={shirt} alt="img"/>
                                    <img src={mike} alt="img"/>
                                    <img src={cross} alt="img"/>
                                    <img src={mike} alt="img"/>
                                    <img src={shirt} alt="img"/>
                                </div>
                                <img className='bottom-img'src={box2} alt="img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="question">
                <div className="marquee">
                    <div>
                        <h1>Things to do in Fronk World</h1>
                        <BsArrowRight className="icon"/>
                        <h1 className="transparent">Things to do in Fronk World</h1>
                        <BsArrowRight className="icon"/>
                        <h1>Things to do in Fronk World</h1>
                        <BsArrowRight className="icon"/>
                    </div>
                </div>
                <div className="circle"></div>
                <div className="container">

                    <div className="question">
                        <div className="question--quest">
                            <div>
                                <h1>quests</h1>
                                <img src={quest} alt="img"/>
                            </div>
                            <h3>Complete WEB3 quests offered by our partners and earn valuable rewards</h3>
                        </div>
                        <div className="question--quest">
                            <div>
                                <h1>rewards</h1>
                                <img src={star} alt="img"/>
                            </div>
                            <h3>Play and earn rewards directly from Fronk World games itself</h3></div>
                        <div className="question--quest">
                            <div>
                                <h1>ama</h1>
                                <img src={people} alt="img"/>
                            </div>
                            <h3>Attend AMA sessions, both public and private, right within the game</h3></div>
                        <div className="question--quest">
                            <div>
                                <h1>tournaments</h1>
                                <img src={karona} alt="img"/>
                            </div>
                            <h3>Gear up for gaming tournaments and other web3 events, where you can get exclusive
                                rewards</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div id='accordion'>
                <div className="circle"></div>
                <div className="container" >
                    <div className="blocks">
                            <h1 id='faq'>FAQ</h1>
                    <div className="blocks--accordion">
                    {information.map((item, index) => (
    <div key={index} className="item">
        <div className="ques" onClick={() => handleClick(index)}>
            {item.zagalovok}
            <div className='plus' >{activeIndex === index ? '-' : '+'}</div>
        </div>
        {activeIndex === index && (
            <div className="answer">{item.text}</div>
        )}
    </div>
))}
          </div>
        </div>
      </div>
    </div>
     </div>
    );
};

export default FirstPage;