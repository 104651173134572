import React, {useEffect, useState} from 'react';
import {BsDiscord, BsLinkedin, BsTwitter} from "react-icons/bs";
import logo from "../../img/logo.png"
import {AiOutlineMinus} from "react-icons/ai";
import {NavLink} from "react-router-dom";

const Header = () => {
    const [isBurgerVisible, setIsBurgerVisible] = useState(window.innerWidth < 775);
    const [burger, setBurger] = useState(false)
    useEffect(() => {
        const handleResize = () => {
            setIsBurgerVisible(window.innerWidth < 775);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id="header">
            <div className="container">
                <div className="header">
                    <div className="header--left">
                        <a href="https://twitter.com/FronkWorld"> <BsTwitter className="icon"/></a>
                        <a href="https://discord.com/invite/saH5FYreF7 "> <BsDiscord className="icon"/></a>

                    </div>
                    <div className="header--center">
                        <img src={logo} alt="img"/>
                    </div>
                    <div className="header--right">
                        <NavLink to={"/"}><h3>Home</h3></NavLink>
                        <NavLink to={"/mint"}><h3>Mint</h3></NavLink>
                        <NavLink to={"https://bridge.fronk.world/"} target="_blank"><h3>Bridge NFT</h3></NavLink>
                        <NavLink to={"https://fronk.world/#faq"}><h3>Faq</h3></NavLink>
                    </div>
                    <div className="header--burger" style={{
                        display: isBurgerVisible ? "block" : "none",
                        background: burger ? "white" : "",
                        transition: ".5s"
                    }}
                    >
                        <div className="header--burger__menu"
                             onClick={() => setBurger(!burger)}>
                            <AiOutlineMinus className="icon" style={{
                                transform: burger && "rotate(-32deg)",
                                marginTop: burger ? "14px " : "",
                                transition: ".5s"
                            }}/>
                            <AiOutlineMinus className="icon" style={{
                                transform: burger && "rotate(32deg)",
                                marginBottom: burger ? "8px " : "",
                                transition: ".5s"
                            }}/></div>

                        <div className="header--burger__title" style={{
                            display: burger ? "block" : "none"
                        }}>
                            <div className="header--burger__title--top">
                                <a href="https://twitter.com/FronkWorld"> <BsTwitter className="icon"/></a>
                                <a href="https://discord.com/invite/saH5FYreF7 "> <BsDiscord className="icon"/></a>
                            </div>
                            <div className="header--burger__title--bottom">
                                <NavLink to={"/"} onClick={()=>{
                                    setBurger(false)
                                }}><h3>Home</h3></NavLink>
                                  <NavLink to={"/mint"} onClick={()=>{
                                    setBurger(false)
                                }}><h3>Mint</h3></NavLink>
                                  <NavLink to={"https://bridge.fronk.world/"} target="_blank" onClick={()=>{
                                    setBurger(false)
                                }}><h3>Bridge NFT</h3></NavLink>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;