import React from "react";
import boxImg from "../../img/Box (2).png";
import boxImg2 from "../../img/Box (2).png";
import linesImg from "../../img/Gradient_lines (1).png";
import cardimg from "../../img/Card_pass_1к 1 (1).png";
import { BsArrowRight } from "react-icons/bs";

const LandSale = () => {
    const greenBlocks=[
        {
        id:1,
        title:'1 quarter 2023',
        exp:'Mint Genesis NFT, Heroes art concepts, Launch of the community program, work on Beta.'
    },
    {
    id:2,
    title:'2 quarter 2023',
    exp:'Closed beta test, launch of beta version.'
},
{
id:3,
title:'3 quarter 2023',
exp:'Launching of AMA space, tokensale.'
},
{
id:4,
title:'4 quarter 2023',
exp:'marketplace launch, arming and staking, partnership events.'
},
{
id:4,
title:'1 quarter 2024',
exp:'alpha launch, first game mode.'
}]
  return (
    <div id="landSale">
      <div id="hero">
        <div className="container">
          <div className="hero">
            <div className="hero--circle"></div>
            <h1 className="mint">Mint</h1>
            <h2>Fronk</h2>
            <img className="hero--boxImg" src={boxImg} alt="" />
            <img className="hero--linesImg" src={linesImg} alt="img" />
            <h1 className="ticket">Ticket NFT</h1>

            
            <div className="hero--journey">
              <h3>and start your journey</h3>
              <div className="hero--journey__array">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="312"
                  height="2"
                  viewBox="0 0 312 2"
                  fill="none"
                >
                  <path d="M0 1H312" stroke="#D9D9D9" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="38"
                  viewBox="0 0 20 38"
                  fill="none"
                >
                  <path d="M1 0.941162L19 18.9412L1 36.9412" stroke="#D9D9D9" />
                </svg>
              </div>
              <button>Join the mint list </button>
            </div>
          </div>
        </div>
      </div>
       <div id="section">
           <div className="second">
               <div className="second--line"></div>
               <div className="container">
                   <div className="second--page">
                       <div className="second--page__left">
                           <h1>
                               Fronk <br />
                               Ticket <br /> NFT
                           </h1>
                           <img src={cardimg} alt=""/>                       </div>
                       <div className="second--page__title">
                           <p>
                               The Fronk Ticket is your gateway to Fronk World. As proud owners
                               of this NFT, you gain access to all public events, the exciting
                               opportunity to attend partner-hosted AMAs, and a plethora of
                               rewards both in and out of the game. Consider this your
                               exclusive pass to Fronkopolis, a gated community buzzing with
                               top-tier events and enticing rewards.
                           </p>
                           <div className="second--page__title--morthe">
                               <div className="second--page__title--morthe__first"></div>
                               <div className="second--page__title--morthe__second"></div>
                               <div className="second--page__title--morthe__third"></div>
                               <div className="second--page__title--morthe__fourth"></div>
                               <div className="second--page__title--morthe__first"></div>
                               <div className="second--page__title--morthe__second"></div>
                               <div className="second--page__title--morthe__third"></div>
                               <div className="second--page__title--morthe__fourth"></div>
                               <div className="second--page__title--morthe__first"></div>
                               <div className="second--page__title--morthe__second"></div>
                               <div className="second--page__title--morthe__third"></div>
                               <div className="second--page__title--morthe__fourth"></div>
                           </div>
                           <div className="second--page__title--text">
                               <h3> Fronk Ticket NFT</h3>
                               <h3> Fronk Ticket NFT</h3>
                               <h3> Fronk Ticke</h3>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           <div className="circle"></div>
            </div>
            <div id="section2">
                <div className="marquee">
                    <div>
                        <h1>road map</h1>
                        <BsArrowRight className="icon" />
                        <h1 className="transparent">road map</h1>
                        <BsArrowRight className="icon" />
                        <h1>road map</h1>
                        <BsArrowRight className="icon" />
                        <h1 className="transparent">road map</h1>
                        <BsArrowRight className="icon" />
                        <h1>road map</h1>
                        <BsArrowRight className="icon" />
                        <h1 className="transparent">road map</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="section2">
                        <div className="section2--lights"></div>
                        <div className="section2--blocks">
                            
                                {
                                    greenBlocks.map(el=>(
                                        <div className="section2--blocks__block" style={{border: el.title === "1 quarter 2023" || el.title === "2 quarter 2023" ? 'green solid 2px' : '' }}>
                                            <h1 style={{color: el.title === "1 quarter 2023" || el.title === "2 quarter 2023" ? 'green' : '' }}>{el.title}</h1>
                                            <p style={{color: el.title === "1 quarter 2023" || el.title === "2 quarter 2023" ? 'green' : '' }}>{el.exp}</p>

                                        </div>
                                    ))
                                }
                            <img
                                className="section2--blocks__image"
                                src={boxImg2}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
    </div>
  );
};

export default LandSale;
